import React, { useEffect, useState } from "react"
import queryString from "query-string"
import { Layout, SEO, CJumbotron, CBreadCrumb, MicroCmsLayout, ShopContents, LWrap } from "../../../components/_index"

// markup
const SubPages = ({ location }: any) => {
  const { contentId, draftKey } = queryString.parse(location.search);
  const [data, setData] = useState<any | null>(null);
  useEffect(() => {
    fetch(`https://${process.env.MICRO_CMS_SERVICE_DOMAIN}.microcms.io/api/v1/shop_yrph/${contentId}?draftKey=${draftKey}`, {
      headers: {
        'X-MICROCMS-API-KEY': process.env.MICRO_CMS_API_KEY || "",
      },
    })
      .then(res => res.json())
      .then(res => setData({ res }));
  }, []);

  if (data === null) {
    return null;
  }

  return (
    <Layout>
      <SEO title="プレビュー"
        description="プレビュー"
        ogtype={"blog"}
      />
      <CJumbotron
        type="ja"
        size="small"
        data={{
          title: {
            main: 'プレビュー',
            sub: 'プレビュー',
          },
          imgList: [
            {
              img: {
                src: 'https://placehold.jp/1280x200.png',
              },
              imgSp: {
                src: 'https://placehold.jp/1280x200.png',
              },
            },
          ],
        }}
      >
      </CJumbotron>
      <section className="l_sect">
        <p>コンテンツ1</p>
        <LWrap>
          <ShopContents data={data.res.contents1} />
        </LWrap>
      </section>

      <section className="l_sect u_bgWhite">
        <p>コンテンツ2（各レストランのメニューページのみ利用されます。コンテンツ1の下に続けて表示されます）</p>
        <LWrap>
          <ShopContents data={data.res.contents2} />
        </LWrap>
      </section>
    </Layout>
  )
}

export default SubPages;
